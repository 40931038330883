<template>
  <div class="board">
    <h1>{{ this.$t("gnb.notice") }}</h1>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Notice",
  data: function () {
    return {
      process_queue: [],
      data_fully_loaded: false,
      search_page: 1,
      notice_data: [],
      total_count: 0,
    };
  },
};
</script>

<style scoped>
.board {
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem;
}
.board > h1 {
  font-size: 1.6rem;
  font-weight: 700;

  padding: 0;
  margin: 0 0 2rem 0;
}
</style>
